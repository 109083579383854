import React, {useState} from 'react'
import { Form } from "react-bootstrap" 
import Select from 'react-select';
import Col from "react-bootstrap/Col"

const customStyles = { 
  control: styles => ({ ...styles,outline: 0, boxShadow: 'none', borderRadius:"50px", height:"50px"}),
}


const MultiSelectField = ({ name, required, placeholder, values, handlechange, fieldClass, label, grpmd, componentprops, step, type, id, isValid, reset }) => {
  var options = []; 
  const [value, setValue] = useState(null);
  values.map((val, i) =>{ 
    options.push({value: val, label: val }) 
  })

  const multiSelectChange = event => {
    var selectValues = []
    setValue(event);
    event.map((val) => {
  
      selectValues.push(val.value)
    })
    let joined = selectValues.join();
    handlechange(joined) 
  }

  if(reset && value != null){
    setValue(null)
  }

   return(
  
  <Form.Group as={Col} md={grpmd} className={`${type} ${step} multi-selectbox`} controlId={"validation" + name}>
    {label
      ? <Form.Label className="form-label">{label}{required ? '' : ''}</Form.Label>
      : ''

    }
    {/* <Multiselect 
		className={fieldClass}
		required={required}
		name={name}
		onChange={handlechange}
		as="select"
    value={componentprops ? componentprops[name] : ''}
		>
    <option value="" key="">{placeholder}</option>
		{values.map((val, i) =>{ return (<option value={val} key={i}>{val}</option>) })}
    </Multiselect> */}
    <Select 
      isMulti
      name={name}
      value={value}
      required={required}
      options={options} 
      className={`${fieldClass} ${isValid? '': 'multiselect-error'}`}
      onChange={multiSelectChange}
      classNamePrefix="select"
      placeholder={placeholder}
      styles={customStyles}
    />
  </Form.Group>

)};

export default MultiSelectField;