import { Link } from "@StarberryUtils";
import React, { useState, useEffect, Fragment } from "react"
import { Button, Form, Container, Row, Col, Fade } from 'react-bootstrap';
import AreaGuidImg from "../../../images/area-guid_img.jpg"
import AreaGuidImg_t from "../../../images/area-guid_img_t.jpg"
import AreaGuidImg_m from "../../../images/area-guid_img_m.jpg"
import HTMLReactParser from "html-react-parser";
import PlayVideo from "../../Play/CustomVideo";
import _ from "lodash";

import { inViewOptions, containerVariants, fadeInFromLeft, fadeInFromTop } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

// Style
import './AreaGuid.scss';

const AreaGuid = (props) => {
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="areaguid_section mt-lg-0 mt-5"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <div className="areaguid_wrap overflow-hidden">
            <Container>
              <Row>
                <motion.div className="areaguid_img" variants={fadeInFromLeft}>
                  {props.Tile_Image &&
                    <picture>
                      <source media="(min-width:992px)" srcSet={props.Tile_Image.url} alt={props.Tile_Image.alternativeText} />
                      <source media="(min-width:768px)" srcSet={props.Tile_Image.url}  alt={props.Tile_Image.alternativeText} />
                      <img className="" src={props.Tile_Image.url} alt={props.Tile_Image.alternativeText} />
                    </picture>
                  } 
                  {props.Embed_Video_URL &&
                    <PlayVideo url={props.Embed_Video_URL} icon="icon-play" class="play-btn" buttonName="View Video Guide" />
                  }

                </motion.div>
                <Col lg="7" xl="6" className="ml-lg-auto">
                  <motion.div className="areaguid_content" variants={fadeInFromTop}>
                    <span className="sub-title">{props.Area_Title}</span>
                    {props.Modules_Area_Guides && props.Modules_Area_Guides.map((guide, i) =>
                      <Fragment key={i}>
                        {!_.isEmpty(guide.Intro_Content) &&
                          HTMLReactParser(guide.Intro_Content.replace(/<br\s*\/?>/gi, ''))
                        }
                      </Fragment>
                    )}
                    <Link to={`/about/area-guides/${props.URL}`} className="btn btn-outline">View Area Guide</Link>
                  </motion.div>
                </Col>
              </Row>

            </Container>
          </div>
        </motion.div>
      )}
    </InView>

  )
}



export default AreaGuid