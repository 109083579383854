import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Pagination } from 'react-bootstrap';
import Agent01 from "../../../images/static/agent.png"
import Agent02 from "../../../images/static/agent02.png"
import HTMLReactParser from "html-react-parser";
import _ from "lodash"
// Style
import './Agents.scss';

import { inViewOptions, containerVariants, fadeInFromBottom, fadeInFromRight } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const Agents = (props) => {
    const officeName = (item) => {
        if (_.toLower(item.Designation) === "branch manager" || _.toLower(item.Designation) === "area manager") {
            return _.join(_.map(item.offices, 'Name'), ' & ');
        }
    }
    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className="agents"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}
                >
                    <Container>
                        <Row>
                            <Col>
                                <div className="agents-wrapper">
                                    <motion.div className="about-agents" variants={fadeInFromBottom}>
                                        <span className="sm-text">{props.Title}</span>
                                        <h2>{props.Sub_Title}</h2>
                                        {props.Description &&
                                            HTMLReactParser(props.Description.replace(/<br\s*\/?>/gi, ''))
                                        }

                                        <div className={`about-agent ${props.URL}`}>
                                            {props.team_contacts && props.team_contacts.map((team, i) =>
                                                <div className="agent-info" key={i}>
                                                    <div className="agent-img">
                                                        <img src={team.Tile_Image.url} alt={`${team.Name} - DBRoberts`} />
                                                    </div>
                                                    <div className="agent-details">
                                                        <h3>{team.Name}</h3>
                                                        <span className="profile">{officeName(team)} {team.Designation}</span>
                                                        <a href={`tel:+44${team.Phone}`}>{team.Phone}</a>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </motion.div>
                                    <motion.div className="details" variants={fadeInFromRight}>
                                        {props.Office_Hours && props.Office_Hours.map((office, i) =>
                                            <div className="timing" key={i}>
                                                <h3>{office.Title}</h3>

                                                {office.Office_Timings.map((time, i) =>
                                                    <span key={i}>{time.Timing}</span>
                                                )}
                                            </div>
                                        )}
                                    </motion.div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </motion.section>
            )}
        </InView>
    )
}

export default Agents