import { Link } from "@StarberryUtils";
import * as React from "react"
import { Container, Row, Col } from 'react-bootstrap';
// import HTMLReactParser from 'html-react-parser';
// import { useStaticQuery, graphql } from "gatsby";
import { GetURL } from "../../Common/site/functions";
import _ from "lodash";
import "./Getproperty.scss"

import { inViewOptions, containerVariants, fadeInFromRight, fadeInFromBottom } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

// markup
const Getproperty = (props) => {
    const content = props;
    let link_1 = "";
    let link_2 = "";
    if (!_.isEmpty(content)) {
        if (!_.isEmpty(content.CTA_1_URL)) {
            link_1 = GetURL(content.CTA_1_URL.id);
        }
        if (!_.isEmpty(content.CTA_2_URL)) {
            link_2 = GetURL(content.CTA_2_URL.id);
        }
    }
    return (
        <>
            {content &&
                <InView {...inViewOptions}>
                    {({ ref, inView }) => (
                        <motion.div
                            className="get-property overflow-hidden"
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={containerVariants}
                        >
                            <Container>
                                <Row>
                                    <Col xl={12}>
                                        <div className="get-property-block">
                                            <motion.h2 variants={fadeInFromRight}>{content.Title}</motion.h2>
                                            <motion.div className="btn-row" variants={fadeInFromBottom}>
                                                {content.CTA_1_URL && link_1[1] === 'Internal' &&
                                                    <Link to={`/${link_1[0]}`} className="btn btn-primary">{content.CTA_1_Label} <i className="icon-arrow"></i></Link>
                                                }
                                                {content.CTA_1_URL && link_1[1] === 'External' &&
                                                    <a href={`${link_1[0]}`} target="_blank" className="btn btn-primary">{content.CTA_1_Label} <i className="icon-arrow"></i></a>
                                                }

                                                {content.CTA_2_URL && link_2[1] === 'Internal' &&
                                                    <Link to={`/${link_2[0]}`} className="btn btn-primary">{content.CTA_2_Label} <i className="icon-arrow"></i></Link>  
                                                }
                                                {content.CTA_2_URL && link_2[1] === 'External' &&
                                                    <a href={`/${link_2[0]}`} target="_blank" className="btn btn-primary">{content.CTA_2_Label} <i className="icon-arrow"></i></a>
                                                }
                                            </motion.div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </motion.div>
                    )}
                </InView>
            }
        </>
    )
}
export default Getproperty