import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Button, Navbar, Nav, Form, Container, FormControl, Row, Col } from 'react-bootstrap';
import ModalTiny from "react-bootstrap/Modal"
import ReachUsForm from "../Forms/react-us-form";
// Images
import BrandLogo from "../../images/logo.svg"
import _ from "lodash"

// import '../Header/Header.scss';
const OfficeFooter = (page) => {
  const [modalContactformOpen, setContactformOpen] = React.useState(false);
  const [office, setOffice] = React.useState("");
  const closeContactformModal = () => {
    setContactformOpen(false);
  }

  const openContactformModal = (office) => {
    setOffice(office)
    setContactformOpen(true);
  }

  return (
    <>
      {page && !page.bannerInView &&
        <>
          <div className={`sticky-footer-bottom d-lg-none`} ></div>
          <div className={`property-footer d-lg-none`} >
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="feature-section sticky-footer">
                    <div className="footer-section-btn">

                      <Link to={`/property-valuation/home-visit-valuation`} className="btn btn-primary  d-md-flex">Book a Valuation<i className="icon-arrow"></i></Link>
                      <Button className="btn btn-primary  d-md-flex" onClick={() => { openContactformModal(page.Name) }}>Message Us<i className="icon-arrow"></i></Button>


                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      }
      <ModalTiny size="lg" show={modalContactformOpen} onHide={closeContactformModal} className="modal-form">
        <ModalTiny.Header closeButton className="contact-close-btn">
          <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Office Contact</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section"><ReachUsForm title="Office Contact" office={office} people_name={""} people_number={""} email_temp_user="reach_us_user" /></ModalTiny.Body>
      </ModalTiny>
    </>
  )
}



export default OfficeFooter