import React, { useRef, useState, useEffect } from "react"
import { Form, Button } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import FileField from './elements/file'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import { getUser } from "@myaccount/website-service"
//import bsCustomFileInput from 'bs-custom-file-input'
import $ from 'jquery/dist/jquery.min.js'


import "../../scss/forms.scss";

import axios from "axios"
import * as qs from "query-string"

function ValuationForm(props) {

  const userData = getUser();
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");
  const [file, setFile] = useState(""); // storing the uploaded file
  // storing the recived file from backend
  const [data, getFile] = useState({ name: "", path: "" });
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element

  const myRef = React.createRef();




  const recaptchaRef = React.createRef();
  const fields = ([
    {
      element: "config",
      formname: "Valuation",
      error_text: "Highlighted fields are required",
      success_text: "Thank you for your interest. We will be in touch with you shortly.",
      email_temp_user: "valuation_user",
      email_temp_admin: "valuation_admin",
      email_subject_user:"Valuation Request",
      email_subject_admin:"Valuation Request",
      email_server_func: "valuation",
      event_tracking: "valuation",
      page_url: "/valuation"
    },
    {
      grpmd: "6",
      label: "First Name",
      placeholder: "Your First Name *",
      name: "first_name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18",
      defaultValue: userData?.name || ``
    },
    {
      grpmd: "6",
      label: "Last Name",
      placeholder: "Your Last Name *",
      name: "last_name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18",
      defaultValue: userData?.surname || ``
    },
    {
      grpmd: "12",
      label: "Email Address",
      placeholder: "Your Email Address *",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      class: "",
      defaultValue: userData?.email || ``
    },
    {
      grpmd: "12",
      label: "Telephone",
      placeholder: "Your Phone Number *",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "content_b-18",
      defaultValue: userData?.mobile || ``

    },
    {
      grpmd: "12",
      label: "Property Postcode",
      placeholder: "Your Postcode *",
      name: "postcode",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18"
    },
    {
      grpmd: "6",
      label: "Preferred Date",
      placeholder: "Preferred Date",
      name: "preferred_date",
      type: "datePicker",
      element: "input",
      required: true,
      class: "form-control datepicker-input",
      step: "stepSix left",
      labelClass: "content_b-18"
    },
    {
      grpmd: "6",
      label: "Preferred Time",
      placeholder: "Preferred Time",
      name: "preferred_time",
      type: "timePicker",
      element: "input",
      required: true,
      class: "mb-4 form-control",
      step: "stepSix right",
      labelClass: "content_b-18"
    },
    {
      text: 'By clicking Submit, you agree to our <a href="/terms-of-use/"> Terms &amp; Conditions </a> and <a href="/privacy-policy/"> Privacy Policy </a>',
      element: "html",
      class: "form-block-text"
    },
    {
      name: (props.submit_btn)? props.submit_btn : "Book Appointment",
      type: "submit",
      element: "button",
      value: "submit",
      step: "col-md-12 submit-btn",
      class: "btn btn-primary",
      formclass: "banner_submit_btn"
    },
    {
      element: "captcha",
      class: "py-2 captcha-class",
      captchaRef: recaptchaRef
    },
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  const handleAttachment = event => {
    const fileItem = event.target.files[0]; // accesing file

    var d = document.getElementById('custom-file');
    //alert(d.value);
    $('.form-control-browsefile .custom-file-label').html(d.value.replace(/C:\\fakepath\\/, ''));

    setFile(fileItem); // storing file 
  };


  useEffect(() => {
    $("#datePicker").keypress(function (evt) {
      return false;
    });

    // bsCustomFileInput.init()
    if (token !== '') {

      const processFromData = async () => {

        formvalues['g-recaptcha-response'] = token;
        let formData = new FormData();
        formvalues['extra'] = JSON.stringify({
          formname: formvalues.formname,
          property_postcode: formvalues.postcode,
          preferred_date: formvalues.preferred_date,
          type: formvalues.type,
          first_name: formvalues.first_name,
          last_name: formvalues.last_name,
          preferred_time: formvalues.preferred_time
        });
        formvalues['name'] = formvalues.first_name + ' ' + formvalues.last_name;
        formvalues['email_subject_user'] = fields[0].email_subject_user;
        formvalues['email_subject_admin'] = fields[0].email_subject_admin;
        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()

          // lets send mail
          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
        });


        const axiosOptions_netlify = {
          url: fields[0].page_url,
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: qs.stringify(formvalues),
        }

        const url = typeof window !== 'undefined' ? window.location.href : ''
        if (props.title != "") {
          fields[0].formname = props.title
        }
        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-' + fields[0].event_tracking,
          'formId': 'form-' + fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': fields[0].formname
        });

        setShowerror(false);
        setThankyou(true);
        setTimeout(() => {
          $(".alert-success").show().delay(4000).fadeOut();
        }, 500)
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    if ((file == '') || (file == null)) {
      $('.form-control-browsefile').addClass('fileerror');
    } else {
      $('.form-control-browsefile').removeClass('fileerror');
    }

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    else {
      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;


      json['files'] = file;
      setFile(file);
      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      setStartDate();
      setStartTime();

    }
  };

  console.log(props)

  return (
    <div className="contact-form">
      <div ref={myRef} />

      {showerror && <div className="alert alert-danger alert-error">
        <p>{fields[0].error_text}</p>
      </div>}

      {showthankyou && <div className="alert alert-success">
        <p>{fields[0].success_text}</p>
      </div>}

      <Form className="form book-valuation-form" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} netlify-honeypot="bot-field" data-netlify-recaptcha="true">
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="type" value={props.type} />
        <input type="hidden" name="bot-field" />
        <div className="row">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  startDate={field.type === 'datePicker' ? startDate : ''}
                  setStartDate={field.type === 'datePicker' ? setStartDate : ''}
                  startTime={field.type === 'timePicker' ? startTime : ''}
                  setStartTime={field.type === 'timePicker' ? setStartTime : ''}
                  label={field.label}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  step={field.step}
                  defaultValue={field?.defaultValue}
                />
              );
            }
            if ("file" === field.element) {
              return (
                <FileField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  label={field.label}
                  type={field.type}
                  fieldClass={field.fieldClass}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  key={`${field.element}~${index}`}
                  accept={field.accept}
                  handlechange={handleAttachment}
                  required={field.required}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  id={field.id}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  step={field.step}
                  formclass={field.formclass}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
          }
        </div>
      </Form>


    </div>
  );
}


const ValuationFormPage = (props) => (
  <ValuationForm classone={props.classone} title={props.title} type={props.type} submit_btn={props.submit_btn} />
)

export default ValuationFormPage