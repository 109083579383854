/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

function Seo({ description, lang, meta, title, image, location }) {
    const site = useStaticQuery(
        graphql`
            query {
                glstrapi{
                    siteConfig {  
                        Site_Title 
                        Site_Description 
                    }
                }
                
               
            }
        `
    )

    //const metaDescription = description;
    const { pathname } = useLocation()
    const metaDescription = description || site.glstrapi.siteConfig?.Site_Description
    const defaultTitle = site.glstrapi?.siteConfig?.Site_Title

    var curentUrl = '';
    curentUrl = process.env.GATSBY_SITE_URL+pathname;
    if (!curentUrl.endsWith('/'))
        curentUrl = curentUrl + '/';

    //remove multiple slashes
    curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1");
    //set lowercase url
    curentUrl = curentUrl.toLowerCase();

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            //  titleTemplate={title ? `%s | ${title}` : null}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
            link={[
                {
                    href: curentUrl,
                    rel: "canonical"
                }
            ]}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:url`,
                    content: curentUrl,
                },
                {
                    property: `og:image`,
                    content: image,
                },
                {
                    property: `og:site_name`,
                    content: 'DB Roberts',
                },
                {
                    name: `twitter:site`,
                    content: "@dbroberts",
                },
                {
                    name: `twitter:card`,
                    content: "summary_large_image",
                },
                {
                    name: `twitter:creator`,
                    // content: site.siteMetadata?.author || ``,
                    content: ``,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:image`,
                    content: image,
                },
                {
                    name: ` twitter:image:src`,
                    content: image,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:domain`,
                    content: "www.dbroberts.co.uk",
                },

            ].concat(meta)}
        />
    )
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default Seo
