import React, { Fragment, useState } from "react";
import { Button, Form, Container, Row, Col, Pagination } from 'react-bootstrap';
import Header from '../Header/Header'
import Breadcrumbs from '../Footer/Breadcrumb/Breadcrumb'
import Footer from '../Footer/Footer'
import OfficeBanner from '../Home/Banner/OfficeBanner'
import AreaGuid from '../ProductDetails/AreaGuid/AreaGuid'
import Agents from '../StaticPage/Agents/Agents'
import Getproperty from "../Home/Getproperty/Getproperty";
import Reviews from "../Home/Reviews/Reviews";
import FeaturedProperties from '../GuideDetails/FeaturedProperties/FeaturedProperties'
import Map from '../map/office-details-map'
import _ from "lodash"
import HTMLReactParser from "html-react-parser";
import Agent01 from "../../images/static/agent.png"
import Agent02 from "../../images/static/agent02.png"
import OfficeFooter from "../Footer/OfficeFooter";
import { InView } from 'react-intersection-observer';

const OfficeDetail = (props) => {
    const [showMenu, setShowMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [bannerInView, setBannerInView] = useState(true);

    const handlerClick = () => {
        var root = document.getElementsByTagName('html')[0];
        setShowMenu(!showMenu)
        if (showMenu == true) {
            document.body.className = '';
            root.className = '';
        } else {
            document.body.classList.add("overflow-hidden");
            root.classList.add("overflow-hidden");
        }
    }

    const handlerSearchClick = () => {
        setShowSearch(!showSearch)
        setShowMenu(false)
        if (showSearch == true) {
            document.body.className = '';

        } else {
            document.body.classList.add("overflow-hidden");
        }
    }
    if (props.data?.teams) {
        props.data.teams = _.take(props.data.teams, 2);
    }

    return (
        <>
            <div className={` ${(showMenu || showSearch) ? "wrapper open-search-block" : "wrapper "}`}>
                <Header showMenu={showMenu} handlerClick={handlerClick} handlerSearchClick={handlerSearchClick} showSearch={showSearch} fixed={true} />
                <InView as="div" onChange={(inView, entry) => setBannerInView(inView)}>
                    <OfficeBanner {...props.data} />
                </InView>
                <Agents {...props.data} />
                {props.data.Latitude && props.data.Longitude &&
                    <Map lat={props.data.Latitude ? parseFloat(props.data.Latitude) : ''} log={props.data.Longitude ? parseFloat(props.data.Longitude) : ''} />
                }
                <AreaGuid {...props.data.Area_Guide} />
                {props.data.Modules && props.data.Modules.map((each, i) =>
                    <Fragment key={i}>
                        {each.Title_With_Collection == "Featured_Properties" &&
                            <FeaturedProperties {...each} officeId={props.data?.Office_crm_id} />
                        }
                        {each.Reviews_Background_Image &&
                            <Reviews {...each} />
                        }
                        {each.type == "GLSTRAPI_ComponentModulesFooterBlock" &&
                            <Getproperty {...each} />
                        }
                    </Fragment>
                )}
                <Breadcrumbs tag="details" category="About" subCategory={null} data={[]} page={props.data.Title} />
              
                <Footer popularSearchType='areadetails' title={props.data.Name} />
                <OfficeFooter {...props.data}  bannerInView={bannerInView}/>
            </div>
        </>
    )
}

export default OfficeDetail;