import { Link } from "@StarberryUtils";
import React, { useContext, useState, useEffect } from "react";
import {
  Accordion,
  Card,
  Breadcrumb,
  Container,
  Row,
  Col,
} from "react-bootstrap"; 
import { GetURL } from "../../Common/site/functions";  
 
const Breadcrumbs = (props) => { 

  let url_1 = ''
  if (props.data.Main_Parent) {
    url_1 = GetURL(props.data.Main_Parent.id)
  }
  let url_2 = ''
  if (props.data.Sub_Parent) {
    url_2 = GetURL(props.data.Sub_Parent.id)
  }
 
  return (
    <React.Fragment> 
      {props.page !== "Home" &&
        <div className="breadcrumb-block">
          <Container>
            <Row>
              <Col xl="12">

                <Breadcrumb>
                  <li className="breadcrumb-item one"><Link to="/">Home</Link></li>
                  {props.data.Main_Parent &&
                    <li className="breadcrumb-item one"><Link to={`/${url_1[0]}`}>{props.data.Main_Parent.Label}</Link></li>
                  }
                  {props.data.Sub_Parent &&
                    <li className="breadcrumb-item one"><Link to={`/${url_2[0]}`}>{props.data.Sub_Parent.Label}</Link></li>
                  }
                  {props.tag === "details" && props.category && props.subCategory === null &&
                   <li className="breadcrumb-item one"><Link to={`../`}>{props.category}</Link></li>
                  }
                  {props.tag === "details" && props.category && props.subCategory !== null &&
                   <li className="breadcrumb-item one"><Link to={`../../`}>{props.category}</Link></li>
                  }
                  {props.tag === "details" && props.subCategory &&
                      <>
                      {props.subCategory === "Area Guide" ?
                        <li className="breadcrumb-item one"><Link to={`/about/area-guides`}>{props.subCategory}</Link></li>
                        :
                        <li className="breadcrumb-item one"><Link to={`../`}>{props.subCategory}</Link></li>
                      }
                    </> 
                  }
                  {props.page &&
                    <li className="breadcrumb-item one active">{props.page}</li>
                  }

                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
      } 
    </React.Fragment>

  )
}
export default Breadcrumbs;
