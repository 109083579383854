import { Link } from "@StarberryUtils";
import React, { useState } from "react"
import { Container, Row, Col, Button } from 'react-bootstrap';
import ModalTiny from "react-bootstrap/Modal"
import ReachUsForm from "../../Forms/react-us-form";
import ValuationForm from "../../Forms/valuation-form";
// Images
import VideoPosterLarge from "../../../images/home/banner_d.jpg"
import VideoPosterMedium from "../../../images/home/banner_t.jpg"
import VideoPosterSmall from "../../../images/home/banner_m.jpg"
import FeefoLogo from "../../../images/feefo-logo.svg"
import { feefo } from "../../Common/site/functions"
// Style
import './Banner.scss';
import PlayVideo from "../../Play/CustomVideo";
import HTMLReactParser from "html-react-parser";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";

import { inViewOptions, containerVariants, fadeInFromBottom, contentItemStagger, contentItem } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const MotionCol = motion(Col)

const OfficeBanner = (page) => {
    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    const [Shareicons, setShareicons] = React.useState(false);
    const [avgReview, setAvgReview] = useState(0);
    const [review, setReview] = useState(0);

    const openShareicons = () => {
        setShareicons(true);
    }

    const closeShareicons = () => {
        setShareicons(false);
    }

    const [modalContactformOpen, setContactformOpen] = React.useState(false);
    const [office, setOffice] = React.useState("");
    const closeContactformModal = () => {
        setContactformOpen(false);
    }

    const openContactformModal = (office) => {
        setOffice(office)
        setContactformOpen(true);
    }

    const [modalValuationformOpen, setValuationformOpen] = React.useState(false);
    const closeValuationformModal = () => {
        setValuationformOpen(false);
    }

    const openValuationformModal = () => {
        setValuationformOpen(true);
    }


    const trackerShare = (event) => {
        var shareURL = typeof window !== 'undefined' ? window.location.href : ''
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Share - social icons',
            'formType': event + ' - ' + shareURL,
            'formId': 'Share',
            'formName': 'Share',
            'formLabel': 'Share'
        });
    }

    feefo((review) => {
        if (review) {
            setAvgReview(review.rating.rating)
            setReview(review.rating.service.count)
        }
    })
    return (
        <React.Fragment>
            <InView {...inViewOptions}>
                {({ ref, inView }) => (
                    <motion.div
                        className="banner-section office-banner"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <Container>
                            {page && <Row>
                                <MotionCol lg="7" className="order-lg-1" variants={contentItemStagger}>
                                    <div className="banner-section-content">
                                        <motion.h1 variants={contentItem}>{page.Title}</motion.h1>
                                        <motion.div variants={contentItem}>
                                            <a className="mobile" href={`tel:+44${page.Mobile}`}>{page.Mobile}</a>
                                            <div className="address">
                                                {page.Address &&
                                                    HTMLReactParser(page.Address)
                                                }
                                            </div>
                                        </motion.div>
                                    </div>
                                    <motion.div className="banner-section-btn" variants={contentItem}>
                                        {/* <Button onClick={() => { openValuationformModal() }} className="btn btn-primary">Book a Valuation<i className="icon-arrow"></i></Button> */}
                                        <Link to={`/property-valuation/home-visit-valuation`} className="btn btn-primary">Book a Valuation<i className="icon-arrow"></i></Link>
                                        <Button className="btn btn-primary" onClick={() => { openContactformModal(page.Name) }}>Message Us<i className="icon-arrow"></i></Button>
                                        <div >
                                            <a href={"javascript:;"} onClick={()=>{setShareicons(!Shareicons)}}  className="btn-circle position-relative"> <i className="icon-share"></i>
                                                {Shareicons &&
                                                    <div  className="property-share-social-icons">
                                                        <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                                                            <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                                        </FacebookShareButton>
                                                        <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                                                            <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                                        </TwitterShareButton>
                                                        <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                                                            <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                                                        </LinkedinShareButton>
                                                        <WhatsappShareButton onClick={() => trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                                                            <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                                                        </WhatsappShareButton>
                                                    </div>
                                                }
                                            </a>
                                        </div>
                                    </motion.div>
                                    <motion.div className="google-rating" variants={contentItem}>
                                        <div><img src={FeefoLogo} alt="Feefo logo" /></div>
                                        <p>{avgReview}/5 Rating based on  <Link to={`/about/reviews`} className="banner-btn-review">{review} verified reviews </Link></p>
                                    </motion.div>
                                </MotionCol>
                                <Col lg="5" className="order-lg-2 img-zoom pr-0">
                                    <motion.div className="banner-section-img" variants={fadeInFromBottom}>
                                        <picture>
                                            <source media="(min-width:992px)" srcSet={page.Image.url} />
                                            <source media="(min-width:768px)" srcSet={page.Image.url} />
                                            <img className="" src={page.Image.url} alt={page.Image.alternativeText} />
                                        </picture>
                                        {page.Video_URL &&
                                            <PlayVideo url={page.Video_URL} icon="icon-play" class="play-btn" buttonName="Play" />
                                        }

                                    </motion.div>
                                </Col>
                            </Row>}
                        </Container>
                    </motion.div>
                )}
            </InView>
            <ModalTiny size="lg" show={modalContactformOpen} onHide={closeContactformModal} className="modal-form">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Office Contact</h2></ModalTiny.Title>
                </ModalTiny.Header>
                <ModalTiny.Body className="event-section"><ReachUsForm title="Office Contact" office={office} people_name={""} people_number={""} email_temp_user="reach_us_user" /></ModalTiny.Body>
            </ModalTiny>
            <ModalTiny size="lg" show={modalValuationformOpen} onHide={closeValuationformModal} className="modal-form">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Book a Valuation</h2></ModalTiny.Title>
                </ModalTiny.Header>
                <ModalTiny.Body className="event-section"><ValuationForm type="Book_a_valuation" title="Book a Valuation" submit_btn="Request Appraisal" /></ModalTiny.Body>
            </ModalTiny>
        </React.Fragment>

    )
}



export default OfficeBanner